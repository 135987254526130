const tagHide = () => {
  const tags = document.querySelector('.tag-list');
  if (tags) {
    const tagList = tags.querySelectorAll('.form-check');
    if (tagList.length > 3) {
      for (let i = 3; i < tagList.length; i++) {
        tagList[i].classList.toggle("hide");
      };
    document.querySelector('.increase-options').classList.toggle("hide");
  }
  }
};

const tagShow = () => {
  const tagOpen = document.querySelector('.increase-options');
  if (tagOpen) {
    tagOpen.addEventListener('click', (event) => {
      tagHide();
      document.querySelector('.reduce-options').classList.remove("hide");
    });
  }
}

const tagClose = () => {
  const tagClose = document.querySelector('.reduce-options');
  if (tagClose) {
    tagClose.addEventListener(('click'), (event) => {
      tagHide();
      document.querySelector('.reduce-options').classList.add("hide");
    });
  }
}

export { tagHide, tagShow, tagClose };
