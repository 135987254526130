const deactivateItems = items => {
  items.forEach(item => {
    item.classList.remove('active');
  });
};


const addLinkInNavbarToggle = () => {
  const navBarItems = document.querySelectorAll('.navbar-link');
  // console.log(navBarItems);
  deactivateItems(navBarItems);
  const webPath = window.location.href.replace(/^(?:\/\/|[^\/]+)*\//, "");
  // console.log(webPath);
  if (webPath === "recipes") {
    navBarItems[0].classList.add('active');
  } else if (webPath === "recipes/new") {
    navBarItems[1].classList.add('active');
  } else if (webPath === "items") {
    navBarItems[2].classList.add('active');
  }
};

export { addLinkInNavbarToggle }
