import "bootstrap";

import {hideFilter} from '../components/filter';
import {maintainPhoto} from '../components/new_recipe';
import {formGuide} from '../pages/new';
import {selectCategory} from '../components/discover';
import {imageDisplay} from '../pages/new';
import {ensureValid, replaceListName } from '../pages/new';
import {imageUploadControl} from '../components/image_upload_control';
import {autoSubmit} from '../pages/index';
import {selectList, filterDone, activateDropDown} from '../pages/items_index';
import {portion} from '../components/portions';
import {showModal} from '../components/modal';
import {addLinkInNavbarToggle} from '../components/navbar_activation';
import {autocompleteSearch} from '../components/autocomplete';
import {tagHide, tagShow, tagClose} from '../components/tag_hide';

const ingredients = ["Abgeriebene Zitronenschale", "Avocado", "Apfel", "Ananas", "Anchovies", "Aprikose", "Artischocke",
 "Balsamico", "Basilikum", "Birne", "Blumenkohl", "Butterschmalz", "Butter", "Bohnen", "Banane", "Brasse",
 "Brauner Zucker", "Brokkoli", "Brunnenkresse", "Buttermilch", "Bärlauch", "Bohnenkraut", "Basmati Reis",
 "Berberitze", "Bergamotte", "Cayennepfeffer", "Champignon", "Chevré", "Chicoree", "Chilli", "Chilliflocken", "Chillipulver",
 "Chillischote", "Couscous", "Curryblätter", "Currypulver", "Cantaloupe Melone", "Cashewnuss", "Cumin",
 "Dattel", "Dattel, getrocknet", "Dill","Dorade", "Ei", "Eier", "Endivie", "Ente", "Entrecote", "Erbse",
 "Erdbeere", "Erdnuss", "Essig", "Entenbrust", "Estragon", "Feige", "Feige, getrocknet", "Fenchel", "Fenchelsamen",
 "Fleur de Sel", "Forelle", "Garnelen", "Gemüsebrühe", "Grapefruit", "Grüne Bohnen", "Grüner Spargel", "Grünkohl",
 "Gurke", "Getrocknete Pflaumen", "Gewürznelke", "Gouda", "Halloumi", "Haselnuss", "Heidelbeere", "Himbeere",
 "Hirsch", "Hirschkeule", "Hirschrücken", "Hokaido Kürbis", "Holunder", "Honig", "Honigmelone", "Huhn", "Hühnerbrust",
 "Hühnerschlegel", "Ingwer", "Joghurt", "Johannisbeere", "Kartoffeln", "Karotte", "Kabeljau", "Kakaopulver", "Kaki",
 "Kalb", "Kalbsfilet", "Kalbsschnitzel", "Kapern", "Kardamom", "Kichererbsen", "Kidney-Bohne", "Kirsche", "Knoblauch",
 "Knollensellerie", "Kohl", "Kohlrabi", "Kokos", "Kokosfett", "Kopfsalat", "Koriander", "Kren", "Kresse", "Kreuzkümmel",
 "Kurkuma", "Käse", "Kümmel", "Kürbis", "Kürbiskernöl", "Lachs", "Lamm", "Lammkeule", "Lammrücken", "Langer Pfeffer",
 "Lauch", "Liebstöckel", "Limette", "Linsen", "Lorbeerblatt", "Lorbeerblätter", "Meersalz", "Mehl", "Milch", "Majoran",
 "Makrele", "Mandarine", "Mandel","Mango", "Mangold", "Maniok", "Meerrettich", "Melone", "Minze", "Mozarella", "Muskat",
 "Möhren", "Nektarine", "Nelke", "Nudeln","Olivenöl", "Oliven", "Oliven, entkernt", "Oliven, schwarz", "Orange",
 "Orangen", "Orangenschale", "Oregano", "Okra", "Pfeffer", "Parmesan","Pilze", "Paprika", "Paprikapulver", "Passionsfrucht",
 "Pastinake", "Pekannuss", "Peperoni","Petersilie", "Pfefferminze", "Pfifferlinge", "Pfirsich", "Pflanzenöl",
 "Pflaume","Pflaume, getrocknet", "Pinienkerne", "Pinienkerne, geröstet", "Pistazie", 'Pistazienkerne', "Porree", "Preiselbeer", "Prinzess Bohnen", "Pute",
 "Putenbrust", "Pak Choi", "Paniermehl", "Papaya", "Quinoa", "Quitte", "Radieschen", "Raz-el-Hanout", "Reh",
 "Rehkeule", "Rehrücken", "Reis", "Rhabarber", "Rind", "Rinderbrühe", "Rinderfilet", "Rinderhüfte", "Rindersteaks",
 "Rochen", "Romanesco", "Rosenkohl", "Rosinen", "Rosmarin", "Rote Beete", "Rote Johannisbeere", "Roter Pfeffer",
 "Rotkohl", "Rotwein", "Rotweinessig", "Rucola", "Rucola", "Rübe", "Salz", "Sahne", "Salat", "Salbei", "Sauerkirsche",
 "Saure Gurke", "Schalotte", "Schnittlauch", "Schwarzer Pfeffer", "Schwein", "Schweinefilet", "Schweinemedallions",
 "Schweinshaxe", "Seehecht", "Seeteufel", "Sellerie", "Senf", "Senf, grobkörnig", "Sesam", "Shrimps", "Sonnenblumenkerne",
 "Spagetthi", "Spargel, grün", "Spargel, weiß", "Spinat", "Spitzkohl", "Staudensellerie", "Steinpilze","Steinpilze, getrocknet",
 "Sternanis", "Szechuanpfeffer", "Süßkartoffel",  "Safran", "Thunfisch", "Thymian", "Tintenfisch", "Tomate", "Tomaten",
 "Tomaten, getrocknet", "Topinambur", "Trauben", "Truthahn", "Trüffel", "Vanille", "Vanilleschote", "Vanillezucker", "Wacholder",
 "Walnuss","Wasabi","Wasser", "Wassermelone", "Weißer Pfeffer", "Weißer Spargel", "Weißkohl", "Weißwein", "Weißweinessig",
 "Wildschwein", "Wildschweinkeule", "Wirsing", "Wolfsbarsch", "Ziegenquark", "Ziegenkäse", "Zimt", "Zimtstange",
 "Zimtstangen", "Zitrone", "Zitronengras", "Zitronenmelisse", "Zitronenschale", "Zitronenschale, abgerieben",
 "Zucchini", "Zucker", "Zuckerschote", "Zwetschge", "Zwiebel"];

const units = ["ml", "l", "Stück", "kg", "g", "TL", "EL", "Tasse", "Msp.", "Prise", "handvoll", "Zehe", "dl", "Zweige", "Zweig", "Kopf", "Köpfe"].sort();

hideFilter();
maintainPhoto();
formGuide();
selectCategory();
imageDisplay();
ensureValid();
showModal();
autoSubmit();
selectList();
filterDone();
portion();
activateDropDown();
imageUploadControl();
replaceListName();
addLinkInNavbarToggle();
autocompleteSearch(ingredients, '.ingredient-input-field');
autocompleteSearch(units, '.ingredient-unit');
tagHide();
tagShow();
tagClose();
