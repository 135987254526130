const imageUploadControl = () => {
  const recipePicturesIngredients = document.getElementById('recipe_pictures_ingredients');
  if (recipePicturesIngredients) {
    const recipeCountIngredients = document.getElementById('recipe_dedication_ingredients_dedication');
    const recipePicturesSteps = document.getElementById('recipe_pictures_steps');
    const recipeCountSteps = document.getElementById('recipe_dedication_steps_dedication');
    recipePicturesIngredients.addEventListener('change', (event) => {
      let count = recipePicturesIngredients.files.length;
      recipeCountIngredients.value = count;
    });

    recipePicturesSteps.addEventListener('change', (event) => {
      let count = recipePicturesSteps.files.length;
      recipeCountSteps.value = count;
    });
  }
}

export { imageUploadControl }
