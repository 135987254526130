const replaceListName = () => {
  const inputField = document.getElementById('recipe_name');
  // console.log(inputField);
  if (inputField) {
    inputField.addEventListener('blur', (event) => {
      const ingredientList = document.getElementById('recipe_ingredient_lists_attributes_0_name');
      ingredientList.value = inputField.value
  })
  }
}

const formGuide = () => {
  const newForm = document.getElementById('new-form');
  if (newForm) {
    const recipeFormElmt = document.getElementById('recipe-form');
    const recipeStepBtn = document.getElementById('continue-recipe');
    const ingredientsFormElmt = document.getElementById('ingredients-form');
    const ingredientsStepBtn = document.getElementById('continue-ingredients');
    const stepsFormElmt = document.getElementById('steps-form');
    const ingredientsBackBtn = document.getElementById('back-ingredients');
    const stepsBackBtn = document.getElementById('back-steps');

    recipeStepBtn.addEventListener('click', (event) => {
        recipeFormElmt.classList.add("hide");
        ingredientsFormElmt.classList.remove("hide");
    });

    ingredientsStepBtn.addEventListener('click', (event) => {
        ingredientsFormElmt.classList.add("hide");
        stepsFormElmt.classList.remove("hide");
    });

    ingredientsBackBtn.addEventListener('click', (event) => {
      ingredientsFormElmt.classList.add("hide");
      recipeFormElmt.classList.remove("hide");
    });

    stepsBackBtn.addEventListener('click', (event) => {
      stepsFormElmt.classList.add("hide");
      ingredientsFormElmt.classList.remove("hide");
    });

  }

};

const imageDisplay = () => {
  const displayImage = (source) => {
    const recipePhotoOutput = document.getElementById(`image-path-${source}`);
    const recipePhotoBlock = document.getElementById(`img-source-${source}`);
    let sourcePhoto = ''

    if (source === 'show') {
      sourcePhoto = document.getElementById('recipe_photo');
    } else if (source === 'ing') {
      sourcePhoto = document.getElementById('recipe_pictures_ingredients');
    } else if (source === 'steps') {
      sourcePhoto = document.getElementById('recipe_pictures_steps');
    }

    let pathPhoto = sourcePhoto.value;
    if (pathPhoto.length > 1) {
      if (sourcePhoto.files.length > 1) {
        recipePhotoOutput.innerText = `${sourcePhoto.files.length} Bilder werden hochgeladen`
      } else {
        recipePhotoOutput.innerText = `Das folgende Bild wird hochgeladen ${pathPhoto}`;
      }
      recipePhotoBlock.classList.remove('hide');
    }
  }

  const recipePhoto = document.getElementById('recipe_photo');
  const ingPhotos = document.getElementById('recipe_pictures_ingredients');
  const stepsPhotos = document.getElementById('recipe_pictures_steps');

  if (recipePhoto) {
    recipePhoto.addEventListener('change', (event) => {
      displayImage('show');
    });

    ingPhotos.addEventListener('change', (event) => {
      displayImage('ing');
    });

    stepsPhotos.addEventListener('change', (event) => {
      displayImage('steps');
    });
  }
}

const ensureValid = () => {
  const recipePhoto = document.getElementById('recipe_photo');
  const recipeName = document.getElementById('recipe_name');
  const recipeStepBtn = document.getElementById('continue-recipe');
  const newForm = document.getElementById('new-form');
  if (recipePhoto && newForm) {
    recipePhoto.addEventListener('change', (event) => {
      if (recipeName.value.length > 2 && recipePhoto.value.length > 2) {
        recipeStepBtn.removeAttribute("disabled");
        recipeStepBtn.classList.remove('k-button-disabled');
        recipeStepBtn.classList.add('k-button-highlight');
      };
    });
    recipeName.addEventListener('blur', (event) => {
      if (recipeName.value.length > 2 && recipePhoto.value.length > 2) {
        recipeStepBtn.removeAttribute("disabled");
        recipeStepBtn.classList.remove('k-button-disabled');
        recipeStepBtn.classList.add('k-button-highlight');
      };
    });
  };
}


export { formGuide, imageDisplay, ensureValid, replaceListName }
