const submit = (event) => {
  const submit = document.getElementById('submit-search-button');
  submit.click();
}

const refresh = (index) => {
  const buttons = document.querySelectorAll('.check_boxes');
  for(var i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener("change", submit);
  }
}

const autoSubmit = () => {
  const filter = document.getElementById("index-filter-auto");
  if (filter) {
    window.addEventListener("click", function(event) {
    let index = document.getElementById("index-filter-auto");
    if (index) {
      refresh(index);
    }
  })
  }
}

export {autoSubmit}
