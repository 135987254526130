const submit = (event) => {
  const submitButton = document.getElementById('submit-button');
  submitButton.click();
}

const submitForm = () => {
  const category1 = document.getElementById('search_category_vorspeise');
  const category2 = document.getElementById('search_category_hauptgang');
  const category3 = document.getElementById('search_category_beilage');
  const category4 = document.getElementById('search_category_nachtisch');
  const category5 = document.getElementById('search_category_getränke');
  const category6 = document.getElementById('search_category_andere');
  const fields = [category1, category2, category3, category4, category5, category6]

  for(var i = 0; i < fields.length; i++) {
    fields[i].addEventListener("change", submit);
  }
}

const selectCategory = () => {
  const discover = document.getElementById('discover-categories');
  if (discover) {
    submitForm();
  }
}

export { selectCategory }
