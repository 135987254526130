/// Activate selection of shopping list
const toggleDropdown = (event) => {
  const list = document.querySelector('.list-dropdown');
  list.classList.toggle('hover-dropdown');
};


const displayActive = (current, target) => {
  const currentList = document.getElementById(`${current}-recipe`);
  const targetList = document.getElementById(`${target}-recipe`);
  targetList.classList.remove('hidden-recipe');
  currentList.classList.add('hidden-recipe');
}

const changeState = (event) => {
  const currentActive = document.querySelector(".list-card-active")
  currentActive.classList.remove("list-card-active");
  event.target.classList.add("list-card-active");
  const idCurrent = currentActive.dataset.listId;
  const idTarget = event.target.dataset.listId;
  if (idTarget != idCurrent) {
    displayActive(idCurrent, idTarget);
  }
  toggleDropdown(event);
}

const waitForClick = (items) => {
  for(var i = 0; i < items.length; i++) {
    items[i].addEventListener("click", changeState);
  }
}

const selectList = () => {
  const itemsIndex = document.querySelector('.list-index-grid');
  if (itemsIndex) {
    const listCards = document.querySelectorAll('.list-card');
    waitForClick(listCards);
  }
}

/// Filter Done elements from shopping list

const deactivate = (item) => {
  item.parentElement.parentElement.parentElement.style.display = "none";;
}

const activate = (item) => {
  item.parentElement.parentElement.parentElement.style.display = "grid";;
}

const applyFilter = () => {
  const btn = event.target
  const doneItems = document.querySelectorAll('.check-box-done')
  if (btn.innerText === "Erledigte Zutaten ausblenden") {
    doneItems.forEach(deactivate);
    btn.innerText = "Alle Zutaten anzeigen";
  } else if (btn.innerText === "Alle Zutaten anzeigen") {
    doneItems.forEach(activate);
    btn.innerText = "Erledigte Zutaten ausblenden";
  }
}

const filterDone = () => {
  const filterBtn = document.querySelector('.filter-done');
  if (filterBtn) {
    filterBtn.addEventListener("click", applyFilter);
  }
}

/// Dropdown small screen


const activateDropDown = () => {
  const dropBtn = document.querySelector('.list-drop-btn');
  if (dropBtn) {
    dropBtn.addEventListener('click', toggleDropdown);
  }
}

export {selectList, filterDone, activateDropDown}
