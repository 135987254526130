const hideFilter = () => {
  const filterBtn = document.getElementById('filter-button');
  if (filterBtn) {
    filterBtn.addEventListener('click', (event) => {
      const filter = document.querySelector('.additional-filter');
      filter.classList.toggle("hide-on-small")
    })
  }
}

export { hideFilter }
