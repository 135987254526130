const identifyUnit = (html) => {
  let unit = 'Portion'
  if (html.innerText.includes('Einheit')) {
    unit = 'Einheit';
  }
  return unit;
}

const reset = (increment, portion, html) => {
  if (portion >=1) {
    const unit = identifyUnit(html, portion);
    html.dataset.portion = portion;
    if (portion === 1 ) {
      html.innerText = `${portion} ${unit}`;
    } else {
      html.innerText = `${portion} ${unit}en`;
    }
    const inputField = document.getElementById('item_portions');
    if (inputField) {
      inputField.value = portion;
    }
    const ingredients = document.querySelectorAll('.ingredient-quantity');
    if (ingredients) {
      ingredients.forEach((ingredient) => {
        const recipePortions = Number.parseInt(ingredient.dataset.recipePortions, 10);
        const recipeQuantity = parseFloat(ingredient.dataset.itemQuantity);
        const newQuantity = recipeQuantity*portion/recipePortions;
        ingredient.innerText = Math.round(newQuantity*100)/100;
      })
    }
  }
}

const listen = (html, button) => {
  button.addEventListener('click', (event) => {
    const increment = Number.parseInt(button.dataset.portionCounter, 10)
    const portion = Number.parseInt(html.dataset.portion, 10) + increment
    reset(increment, portion, html);
  })
}

const portion = () => {
  const totalPortions = document.querySelector('.portion-indicator');
  if (totalPortions) {
    const buttons = document.querySelectorAll('.portion-counter');
    buttons.forEach((button) => {
      listen(totalPortions, button);
    });
  }
}

export {portion}
