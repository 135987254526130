const showModal = () => {
  const ingModalElmt = document.getElementById('ingredients-modal');
  const stepsModalElmt = document.getElementById('steps-modal');

  if (ingModalElmt) {
    const modalActivateBtnIng = document.getElementById('ing-modal-btn');
    const modalActivateBtnSteps = document.getElementById('steps-modal-btn');

    let modal = "";
    let modalCloseBtn = "";
    let modalFwdBtn = "";
    let modalBackBtn = "";
    let modImages = "";
    let imageLength = "";
    let currentImage = 0

    function setParameters(selected) {
      if (selected === 'ing') {
        modal = document.getElementById('ingredients-modal');
        modalCloseBtn = document.getElementById('ing-modal-close');
        modalFwdBtn = document.getElementById('ing-img-forward');
        modalBackBtn = document.getElementById('ing-img-back');
        modImages = document.querySelectorAll('.ing_image');
        imageLength = modImages.length - 1;

      } else if (selected === 'steps') {
        modal = document.getElementById('steps-modal');
        modalCloseBtn = document.getElementById('steps-modal-close');
        modalFwdBtn = document.getElementById('steps-img-forward');
        modalBackBtn = document.getElementById('steps-img-back');
        modImages = document.querySelectorAll('.steps_image');
        imageLength = modImages.length - 1;
      }
    }

    function toggleModal() {
        modal.classList.toggle("show-modal");
    }

    function windowOnClick(event) {
      if (event.target === ingModalElmt) {
        toggleModal();
      }
    }

    function forward() {
      modImages[currentImage].classList.add('hide');
      if (currentImage < imageLength) {
        currentImage = currentImage + 1
      } else {
        currentImage = 0
      }
      modImages[currentImage].classList.remove('hide');
    }

    function back() {
      modImages[currentImage].classList.add('hide');
      if (currentImage > 0) {
        currentImage = currentImage - 1
      } else {
        currentImage = imageLength
      }
      modImages[currentImage].classList.remove('hide');
    }

    function modalFunctionality() {
      window.addEventListener("click", windowOnClick);
      modalCloseBtn.addEventListener('click', toggleModal);
      modalFwdBtn.addEventListener('click', forward);
      modalBackBtn.addEventListener('click', back);
    }

    function openModal(selected) {
      if (selected === 'ing') {
        ingModalElmt.classList.add("show-modal");
      } else if (selected === 'steps') {
        stepsModalElmt.classList.add("show-modal")
      }
      setParameters(selected);
      modalFunctionality();
    }
    if (modalActivateBtnIng) {
      modalActivateBtnIng.addEventListener('click', (event) => {openModal("ing")});
    }
    if (modalActivateBtnSteps) {
      modalActivateBtnSteps.addEventListener('click', (event) => {openModal("steps")});
    }
  }
}

export { showModal }
